html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-div-wrap {
  display: block;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px dashed white;
  width: 100%;
  height: 100%;
  padding: 16px 11px;
  border-radius: 5px;
}

.csv-table {
  padding: 10px;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .csv-table {
    max-width: 100vw;
  }
}

.tag {
    display: inline-block;
    color: #000000;
    font-size: 0.8rem;
    font-weight: 500;
    margin-right: 7px;
    margin-bottom: 7px;
    background: rgb(247, 250, 251);
    padding: 3px 11px;
    border-radius: 2px;
}

.dashboard-img {
  width: 300px;
  height: 300px;
}

@media only screen and (max-width: 500px) {
  .dashboard-img {
    width: 200px;
    height: 200px;
  }
}

#tfjs-visor-container {
  color: #000000;
  font-size: 23px;
  padding-top: 80px;
}

#tfjs-visor-container > .visor{
  top: 64px;
  max-width: 80%;
}